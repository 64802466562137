/* .thumbs{
    display: flex;
    flex-direction: column;
}

.carousel{
    position: absolute;
    top: 25%;
    left: 10%;
} */

.carousel .thumbs-wrapper{
    margin: auto;
    padding: 20px 0;
    text-align: center;
}